export const inputs = {
  button: {
    add_account: 'Ajouter le compte',
    add_product: 'Ajouter le produit',
    update_account: 'Modifier le produit',
    add_client_class: 'Ajouter la classe client',
    update_client_class: 'Modifier la classe client',
    add_client_category: 'Ajouter la categorie client',
    update_client_category: 'Modifier la categorie client',
    enregistrer: 'Enregistrer',
    valider: 'Valider',
    update_warehouse: 'Modifier le Dépôt',
    add_warehouse: 'Ajouter le Dépôt',
    add_user: "Ajouter l'Admin",
    update_user: "Modifier l'Admin",
    add_ad: 'Ajouter la publicité',
    update_ad: 'Modifier la publicité',
  },
  selectOptions: {
    classes: 'Classes',
    categories: 'Catégories',
    commercial: 'Responsable',
    seller: 'Télévendeuses',
    profiles: 'Profiles',
    zones: 'Zone',
    zonesMultiple: 'Zone(s)',
    sectors: 'Secteur(s)',
    sector: 'Secteur',
    clientClasses: 'Classe(s) Client',
    productCategories: 'Catégorie(s) Produit',
    clientsCategories: 'Catégorie(s) Client',
    categoriesLogistics: 'Catégorie(s) Logistiques',
    warehouses: 'Sélectionner un Dépôt',
  },
  placeholder: {
    search: 'Chercher',
    enter_email: 'Saisir votre email',
    enter_password: 'Saisir votre mot de passe',
    enter_confirm_password: 'Confirmer votre mot de passe',
    enter_first_name: 'Saisir votre prénom',
    enter_last_name: 'Saisir votre nom',
    enter_identifier: 'Saisir votre identifiant',
    enter_phone_number: 'Saisir votre numéro de téléphone',
    enter_address: 'Saisir votre adresse',
    enter_gps: 'Saisir les cordoonnées GPS',
    product_name_fr: 'Désignation français',
    product_name_ar: 'Désignation Arabe',
    product_description_fr: 'Déscription Commerciale du produit français',
    product_description_ar: 'Déscription Commerciale du produit arabe',
    product_real_stock: 'Stock réel du produit',
    product_current_stock: 'Stock disponible du produit',
    product_reference: 'Référence sage',
    unit_Price: 'Prix/Unité',
    max_Stock_Unit: 'Max Stock Unité',
    rack_Price: 'Prix/Présentoir',
    max_Stock_Rack: 'Max Stock Présentoir',
    pack_Price: 'Prix/Carton',
    max_Stock_Pack: 'Max Stock Carton',
    pallet_Price: 'Prix/Palette',
    max_Stock_Pallet: 'Max Stock Palette',
    product_pcb_unit: 'Coefficient',
    product_pcbRack: 'Coefficient',
    product_pcb_package: 'Coefficient',
    product_pcb_pallet: 'Coefficient',
    product_EAN: 'Code EAN13',
    product_moq: 'Min par commande',
    product_max_stock: 'Max stock par commande',
    sage_designationPlaceholder: 'Désignation Sage',
    sage_catalogs: 'Catégories Sage',
    price: 'Prix',
    client_class_name_fr: 'Classe Client en français',
    client_class_name_ar: 'Classe Client en arabe',
    client_category_name_fr: 'Categorie Client en français',
    client_category_name_ar: 'Categorie Client en arabe',
    client_minimum_montant_per_command: 'Minimum Montant par commande',
    saisir_nameAr: 'Saisir le nom en arabe',
    saisir_nameFr: 'Saisir le nom en français',
    saisir_code_sage: 'Saisir le code sage',
    warehouse_name_fr: 'Désignation du Dépôt en français',
    warehouse_name_ar: 'Désignation du Dépôt en arabe',
    warehouse_surfaceArea: 'Superficie Dépôt',
    warehouse_manager: 'Gérant',
    warehouse_taxNumber: 'Matricule Fiscale',

    userCode: 'Code user BO',
    userCodeSage: 'Code user Sage',
    user_first_name: 'Prénom',
    user_last_name: 'Nom',
    select_zone: 'Selectionner une zone',
    select_accecibilite: 'Selectionner une accessibilité',
    select_fluidite: 'Selectionner une fluidité',
    select_locomotives: 'Selectionner une locomotives',
    select_flux: 'Selectionner un flux',
    select_barriere: 'Selectionner une barrière',
    enter_cin: 'Saisir le numéro de la carte d’identité nationale',
    select_client_class: 'Selectionner une classe client',
    select_payment_method: 'Selectionner un mode de paiement',
    select_risque_client: 'Selectionner un risque client',
    select_client_status: 'Selectionner un statut client',
    select_client_category: 'Selectionner une categorie client',
    select_sector: 'Selectionner un secteur',
    select_category_product: 'Selectionner une categorie de produit',
    select_warehouse: 'Selectionner un Dépôt',
    select_agent: 'Selectionner un agent',
    select_chef_zone: 'Selectionner un chef de zone',
    enter_patente: 'Saisir la patente',
    enter_social_reason: 'Saisir la raison sociale',
    enter_promo_code: 'Saisir le code promo',
    minOrderDiscountQuantity: '00',
    profileAccess_name_fr: 'Saisir la désignation en français',
    profileAccess_name_ar: 'Saisir la désignation en arabe',
    profileAccess_description_fr: 'Saisir description en français',
    profileAccess_description_ar: 'Saisir description en arabe',
    profileAccess_abilities: 'Selectionner les accès',
    titleFr: 'Titre en Français',
    titleAr: 'Titre en Arabe',
    redirection_type: 'Type de redirection',
    select_brand: 'Selectionner une marque',

    notification_title: 'Titre',
    notification_content: 'Description',
    category_search: 'Rechercher une catégorie',
    sub_category_search: 'Rechercher une sous catégorie',
    product_search: 'Rechercher un produit',
    price_zero: 'TND 0.000',
  },
  unit: 'Unité',
  rack: ' Présentoir / Lot',
  pack: ' Fardeau / Carton',
  pallet: 'Palette',
  firstName: 'Prénom',
  lastName: 'Nom',
  identifier: 'Identifiant',
  email: 'Email',
  phone: 'Numéro de téléphone',
  password: 'Mot de passe',
  confirmPassword: 'Confirmer le mot de passe',
  reset_password: 'Réinitialiser mot de passe',
  send_password: 'Envoyer le lien de réinitialisation',
  send_notification: 'Envoyer une notification au compte',
  generate_password: 'Générer un mot de passe',
  role: 'Rôle',
  pdp: 'Photo de profil',
  address: 'Adresse',
  type_account: 'Type de compte',
  label_notification: 'Envoyer un email à la personne à props de son compte',
  description_too_long: 'La description est trop longue',
  product_name_fr: 'Désignation français',
  product_name_ar: 'Désignation Arabe',
  product_description_fr: 'Déscription Commerciale du produit français',
  product_description_ar: 'Déscription Commerciale du produit arabe',
  product_real_stock: 'Stock réel du produit',
  product_current_stock: 'Stock disponible du produit',
  product_reference: 'Référence sage',
  product_category: 'Category',
  product_sub_category: 'Sous Category',
  product_photo: 'Photos du produit',
  product_pack_photo: 'Photos colisage produit',
  unit_Price: 'Prix/Unité',
  max_Stock_Unit: 'Max Stock Unité',
  rack_Price: 'Prix/Présentoir',
  max_Stock_Rack: 'Max Stock Présentoir',
  pack_Price: 'Prix/Carton',
  max_Stock_Pack: 'Max Stock Carton',
  pallet_Price: 'Prix/Palette',
  max_Stock_Pallet: 'Max Stock Palette',
  product_EAN: 'Code EAN13',
  sage_designation: 'Désignation Sage',
  support_article: 'Produit homologué ?',
  client_class_name_fr: 'Désignation de la classe Client en français',
  client_class_name_ar: 'Désignation de la classe Client en arabe',

  client_category_name_fr: 'Désignation de la categorie Client en français',
  client_category_name_ar: 'Désignation de la categorie Client en arabe',
  client_minimum_montant_per_command: 'Minimum Montant par commande',
  en_arabe: 'En Arabe',
  en_francais: 'En Français',
  select_category: 'Sélectionner une catégorie mére',

  warehouse_name_fr: 'Désignation du Dépôt en français',
  warehouse_name_ar: 'Désignation du Dépôt en arabe',
  warehouse_surfaceArea: 'Superficie Dépôt',
  warehouse_manager: 'Gérant',
  warehouse_taxNumber: 'Matricule Fiscale',
  warehouse_type: 'Type Dépôt',

  userCode: 'Code user BO',
  userCodeSage: 'Code user Sage',
  user_first_name: 'Prénom',
  user_last_name: 'Nom',
  access_type: "Type D'accès",
  zone_commercial: 'La zone commercial',
  accessibilite_commercial: 'Accessibilité du site commercial',
  fluidite_circulation: 'La fluidité de la circulation',
  locomotives_commercial: 'La présence de locomotives commerciales',
  flux_pietons: 'Les flux des piétons',
  barriere_naturelle: 'Les barrières naturelles',
  primary_sector: 'Secteur primaire',
  secondary_sector: 'Secteur secondaire',
  tertiary_sector: 'Secteur tertiaire',
  addClient: {
    cin: `Carte d'identité nationale`,
    paymentMode: 'Mode de paiement',
    clientStatus: 'Statut du client',
    current_budget_limit: 'Budget limite actuel',
    budget_limit: 'Budget limite',
    client_status: 'Statut du client',
    payment_method: 'Mode de paiement',
    plafond_client: 'Plafond du client',
    sage_code: 'Code Sage',
    zone_chef: 'Chef de zone',
    gbs: 'Cordonnées GPS',
    address: 'Adresse clients',
    gbs_global: 'Cordonnées GPS globale',
    gps_invalid: 'Les coordonnées GPS doivent suivre ce format : 00.000000, 00.000000',
    phone_invalid: 'Le numéro de téléphone doit comporter 8 chiffres',
    mat_fiscale_invalid: 'La patente doit être valide.',
    cin_invalid:
      "Le numéro de la carte d'identité nationale doit comporter 8 chiffres commençant par 0 ou 1",
  },
  sage_catalogs: 'Catégories Sage',
  promoCode: {
    no_prices_article: "Le produit n'a pas de prix",
    promo_code: 'Code promo',
    limit: "Nombre limite d'utilisation du code promo",
    begin_date: 'Date de début',
    end_date: 'Date de fin',
    generate_code_promo: 'Générer un code promo',
    applied_to: 'Appliqué pour',
    all_basket: 'Tous le panier',
    products: 'Produit(s)',
    select_category: 'Selectionner une catégorie',
    select_sub_category: 'Selectionner une/plusieurs sous categorie(s)',
    select_product: 'Selectionner un/plusieurs produit(s)',
    select_zone: 'Selectionner une zone',
    select_sector: 'Selectionner un/plusieurs secteur(s)',
    select_client_class: 'Selectionner une classe client',
    select_client_category: 'Selectionner une categorie client',
    select_client: 'Selectionner un/plusieurs client(s)',
    zone: 'Zone',
    sector: 'Secteur',
    client_class: 'Classe client',
    client_category: 'Categorie client',
    client: 'Client',
    packing: 'Colisage',
    product: 'Produit',
    discount: 'Remise (%)',
    price_after_discount: 'Prix après remise',
    price: 'Prix (TND)',
    promo: 'Promotion',
    promo_value: 'Valeur de la promotion',
    pourcentage: 'Pourcentage',
    montant: 'Montant',
    select_type_promo: 'Selectionner un type de promotion',
    begin_date_error: "La date de début doit être supérieure où égale à la date d'aujourd'hui",
    begin_date_error_update: 'La date de début doit être supérieure où égale à la date configurée',
    end_date_error: "La date de début doit être supérieure à la date d'aujourd'hui",
    end_date_error_code_promo: 'La date de fin doit être supérieure à la date de début',
  },
  valid_min_message_pcb: 'La valeur doit être supérieure à 0',
  addDeal: {
    promotion: 'Promotion',
    minOrderDiscountQuantity: 'Quantité minimum pour la remise',
    begin_date: 'Date de début',
    end_date: 'Date de fin',
    bundle: 'Bundle',
    bundle_condition: 'Bundle Conditionné',
    remise: 'Remise',
    promotion_type: 'Type de promotion',
    stock_bundle: 'Stock du bundle',
    bundle_quantity: 'Quantité du bundle',
    packing_type: 'Type de colisage',
    unit_price: 'Prix unitaire(Avant remise)',
    discount: 'Remise(%)',
    total_stock_bundle: 'Stock total bundle',
    product_price_bundle: 'Prix du produit dans le bundle',
    product_price_discount: 'Prix du produit dans la remise',
    product: 'Produit',
    categories: 'Catégorie',
    sub_categories: 'Sous Catégorie',
    delete_product: 'Supprimer le produit',
    baseline: 'Baseline',
    avantage: 'Avantage',
    product_nature: 'Nature du produit',
    bundle_quantity_error: 'La quantité du produit insuffisante',
    total_price_resume: 'Prix total (Avant remise en Dt)',
    total_price_resume_discount: 'Prix total (Après remise en Dt)',
    table: {
      productName: 'Produit du bundle',
      productQuantityOnUnit: 'Quantité du produit dans le bundle',
      productQuantityOnUnitDiscount: 'Quantité du produit dans la remise',
      productQuantityTotalOnUnit: 'Quantité total du produit dans le bundle',
      productQuantityTotalOnUnitDiscount: 'Quantité total du produit dans la remise',
      priceWithoutDiscount: 'Prix sans remise(Dt)',
      discount: 'Remise(%)',
      priceWithDiscount: 'Prix après remise(Dt)',
      warehouse: 'Dépôt',
    },
    description: 'Description',
    warehouse: 'Dépôt',
  },
  addOrder: {
    code_client: 'Code-Client sage',
    client_name: 'Nom et prénom',
    address: 'Adresse de livraison',
    responsable: 'Responsable / Catégorie',
    phone: 'Phone client',
    zone: 'Zone',
    categories: 'Catégories',
    sub_categories: 'Sous Catégories',
    stock_error: 'Stock insuffisant',
    moq_error_quantity_min: 'Min OQ non respecté',
    moq_error_quantity_max: 'Max OQ non respecté',
    min_value_quantity: 'La quantité doit être supérieure à 0',
    Table: {
      image: 'Image',
      sage_code: 'Code Sage',
      product_name: 'Désignation produit',
      brand: 'Marque',
      quantity: 'Quantité/Colisage',
      price: 'Prix (TND)',
      stock: 'Stock R/D',
      promo: 'En promotion',
      minMaxOQ: 'Min/Max OQ',
      action: 'Action',
    },
  },
  AddCategoryLogistics: {
    nameFr: 'Nom du catégorie en français',
    nameAr: 'Nom du catégorie en arabe',
    sageCode: 'Code Sage',
    category_commerciale: 'Catégories commerciales',
    depot: 'Dépôt',
  },
  profileAccess_name_fr: 'Désignation en français',
  profileAccess_name_ar: 'Désignation en arabe',
  profileAccess_description_fr: 'Description en français',
  profileAccess_description_ar: 'Description en arabe',
  profileAccess_abilities: 'Selectionner les accès',

  titleFr: 'Titre en Français',
  titleAr: 'Titre en Arabe',
  redirection_type: 'Type de redirection',
};
