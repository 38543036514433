export enum OrderStatusEnum {
  New = 'new',
  Confirmed = 'confirmed',
  Shipped = 'shipped',
  Delivered = 'delivered',
  Canceled = 'canceled',
  Updated = 'updated',
  Preparing = 'preparing',
  Invoiced = 'invoiced',
  Created = 'created',
}
export enum CartStatusEnum {
  Created = 'created',
  Updated = 'updated',
  Cleared = 'cleared',
  Saved = 'saved',
}
export enum DiffsTypesEnum {
  Added = 'added',
  Updated = 'updated',
  Removed = 'removed',
}
export enum DealTypeEnum {
  Standard = 'standard',
  PackageDeal = 'package deal',
  Discount = 'discount',
}
export enum ProductNatureEnum {
  Baseline = 'baseline',
  Advantage = 'advantage',
}
export enum AppliedToPromoCodeEnum {
  Cart = 'cart',
  Product = 'articles',
}
export enum NavSubjectNameEnum {
  Users = 'users',
  Zones = 'zones',
  Sectors = 'sectors',
  Clients = 'clients',
  ClientClasses = 'clientClasses',
  ClientCategories = 'clientCategories',
  Warehouses = 'warehouses',
  Catalogs = 'catalogs',
  AccessProfile = 'accessProfile',
  Articles = 'articles',
  Banners = 'banners',
  Notifications = 'notifications',
  PromoCodes = 'promoCodes',
  HomeSections = 'homeSections',
  Orders = 'orders',
  ShipmentSchedules = 'shipmentSchedules',
  Deals = 'deals',
  SalesLines = 'salesLines',
}
export enum AppActionEnum {
  Manage = 'manage',
  List = 'list',
  Read = 'read',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Archive = 'archive',
  UpdatePin = 'update_pin',
}
export enum LabelPcbEnum {
  unit = 'Unité',
  display = 'Présentoir / Lot',
  carton = 'Fardeau / Carton',
  pallet = 'Palette',
}

export enum DiscountType {
  PERCENT = 'PERCENT',
  AMOUNT = 'AMOUNT',
}

export enum ExpandEnum {
  Children = 'children',
  Delegations = 'delegations',
  Sectors = 'sectors',
  Catalogs = 'catalogs',
  Media = 'media',
  Brand = 'brand',
  BrandMedia = 'brand.media',
  Target = 'target',
  Governorates = 'governorates',
  GovernoratesDelegations = 'governorates.delegations',
  Stocks = 'stocks',
  Agent = 'agent',
  Prices = 'prices',
  MediaChildren = 'children.media',
  ClientZone = 'client.zone',
  Client = 'client',
  hasSupport = 'hasSupport',
  SalesLine = 'salesLines',
  SalesLineWarehouses = 'salesLines.warehouses',
  ExplodedOrdersAgent = 'explodedOrders.agent',
  ExplodedOrdersClient = 'explodedOrders.client',
  ExplodedOrdersClientZone = 'explodedOrders.client.zone',
  ZoneChief = 'zoneChief',
  ClientScopesWarehouse = 'clientScopes.warehouse',
  ClientScopesAgent = 'clientScopes.agent',
  ClientScopesCatalog = 'clientScopes.catalog',
  ClientScopesCatalogChildren = 'clientScopes.catalog.children',
  ClientScopes = 'clientScopes',
  Class = 'class',
  Zone = 'zone',
  Sector = 'sector',
  Category = 'category',
  Profile = 'profile',
  Mappings = 'mappings',
  MappingsSalesLines = 'mappings.salesLines',
  MappingsSectors = 'mappings.sectors',
  MappingsClientClasses = 'mappings.clientClasses',
  MappingsClientCategories = 'mappings.clientCategories',
  CreatedBy = 'createdBy',
  DiscountWarehouses = 'discountWarehouses',
  ArticleCatalogs = 'article.catalogs',
  Article = 'article',
}

export enum ProductTypeEnum {
  Product = 'product',
  Deal = 'deal',
}

export enum ProductPriceTypeEnum {
  Discount = 'discount',
  FlashDiscount = 'flash_discount',
  Featured = 'featured',
}
export enum DealStatesEnum {
  Ended = 'ended', // Expired
  Ongoing = 'ongoing', // Active
  Coming = 'coming', // Upcoming
}
export enum BackendEntityEnum {
  PRICE = 'PRICE',
}
export enum ClaimerTypeEnum {
  Client = 'client',
  User = 'user',
}
export type Nullable<T> = T | null | undefined;
