import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryConfig } from 'redux/api/BaseQueryConfig';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import {
  transformDealsDetails,
  transformDiscountResponse,
  transformDiscountsDetails,
} from 'redux/api/deals/dealsApi.decoder';
import {
  IDetailsDeal,
  IDetailsDealApi,
  IDetailsDiscountsApi,
} from 'types/models/Deals/deals.schema';
import { articlesApi } from 'redux/api/article/articlesApi';
import Paginator from 'types/interfaces/Paginator';
import {
  injectExpand,
  injectFilterStatus,
  injectFilterWarehouseIds,
  paginatorToApiPagination,
} from 'utils/services/api.service';
import { injectParams } from 'utils/helpers/api-url';
import { IDiscountResponse, IDiscountResponseApi } from 'types/models/Discount/discounts.schema';
import { ExpandEnum, Nullable } from 'types/interfaces/sharedType.type';

export const dealsApi = createApi({
  reducerPath: 'dealsApi',
  baseQuery: baseQueryConfig,
  tagTypes: ['dealsApi', 'remiseApi'],
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    createDeal: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.DEALS.ADD,
        method: 'POST',
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(articlesApi.util.invalidateTags(['Articles']));
      },
    }),
    getDealDetails: builder.query({
      query: (id: string) => ENDPOINTS.DEALS.GET_DETAILS(id),
      transformResponse: (response: IDetailsDealApi): IDetailsDeal => {
        return transformDealsDetails(response);
      },
    }),
    updateDeal: builder.mutation({
      query: ({ id, body }) => ({
        url: ENDPOINTS.DEALS.UPDATE(id),
        method: 'PATCH',
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(articlesApi.util.invalidateTags(['Articles']));
      },
    }),
    createDiscount: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.DEALS.ADD_DISCOUNT,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['remiseApi'],
    }),
    updateDiscount: builder.mutation({
      query: ({ id, body }) => ({
        url: ENDPOINTS.DEALS.UPDATE_DISCOUNT(id),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['remiseApi'],
    }),
    getListDiscount: builder.query({
      query: (params: { paginator?: Paginator; status: boolean | null }) =>
        injectParams(ENDPOINTS.DEALS.GET_DISCOUNT, {
          ...paginatorToApiPagination(params.paginator),
          ...injectFilterStatus(params.status),
        }),
      transformResponse: (response: IDiscountResponseApi): IDiscountResponse => {
        return transformDiscountResponse(response);
      },
      providesTags: ['remiseApi'],
    }),
    getListPositioning: builder.query({
      query: (params: {
        paginator?: Paginator;
        status: boolean | null;
        warehouseIds?: Nullable<number>;
        expand?: Nullable<ExpandEnum[]>;
      }) =>
        injectParams(ENDPOINTS.DEALS.GET_POSITIONING, {
          ...paginatorToApiPagination(params.paginator),
          ...injectFilterStatus(params.status),
          ...injectFilterWarehouseIds(params.warehouseIds),
          ...injectExpand(params.expand),
        }),
      transformResponse: (response: IDiscountResponseApi): IDiscountResponse => {
        return transformDiscountResponse(response);
      },
      providesTags: ['remiseApi'],
    }),
    deleteDiscount: builder.mutation({
      query: (id: string) => ({
        url: ENDPOINTS.DEALS.DELETE_DISCOUNT(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['remiseApi'],
    }),
    getDiscountDetails: builder.query({
      query: (id: string) => ENDPOINTS.DEALS.GET_DISCOUNT_DETAILS(id),
      transformResponse: (response: IDetailsDiscountsApi): IDetailsDeal => {
        return transformDiscountsDetails(response);
      },
      providesTags: ['remiseApi'],
    }),
  }),
});
export const {
  useCreateDealMutation,
  useCreateDiscountMutation,
  useGetDealDetailsQuery,
  useUpdateDealMutation,
  useUpdateDiscountMutation,
  useGetListDiscountQuery,
  useDeleteDiscountMutation,
  useGetDiscountDetailsQuery,
  useGetListPositioningQuery,
} = dealsApi;
