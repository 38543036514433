import { GlobalVariables } from 'config/constant';
import { isNil } from 'lodash';

export const generateRandomNumber = (min = 100000, max = 999999): number => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const shortenNumber = (num: number): string => {
  const abbreviations = ['K', 'M', 'B', 'T'];
  const decimals = 1;
  if (num < 1000) {
    return num.toString();
  }
  const exponent = Math.floor(Math.log10(num) / 3);
  const abbreviation = abbreviations[exponent - 1];
  const converted = num / Math.pow(1000, exponent);
  const rounded = converted.toFixed(decimals);
  return `${rounded}${abbreviation}`;
};

export const convertNumberToString = (num: number | string): string => {
  const str = num.toString();
  const parts = str.split('.');
  const integerPart = parts[0];
  const decimalPart = parts.length > 1 ? ',' + parts[1] : ',00';
  return integerPart + decimalPart;
};

export const priceToString = (price: number, currency: string): string => {
  const priceWithComma = shortenNumber(price);
  return `${convertNumberToString(priceWithComma)} ${currency}`;
};

export const ratingPercentage = (num: number) => {
  const percentage = (num / 5) * 100;
  if (percentage > 100) {
    return 100;
  } else {
    return percentage;
  }
};

export const getDecimalPart = (num: number) => {
  const decimalPart = num.toString().split('.')[1];
  return decimalPart ? decimalPart.split('', 2) : '';
};

export const getWholeNumberPart = (num: number) => {
  return Math.trunc(num);
};
export const getPriceAfterDiscount = (price: number, discount: number) =>
  formattedDigitNumber(price - (price * discount) / 100);

export const formattedDigitNumber = (price: number | undefined): number => {
  return Number(price?.toFixed(GlobalVariables.DecimalDegit)) || 0;
};

export const cToNumber = (c: string | undefined | null | number): number =>
  isNaN(Number(c)) || isNil(c) ? 0 : Number(c);
